"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAudioConstraints = exports.getVideoConstraints = void 0;
const getVideoConstraints = (cameraId) => {
    let videoConstraints = {};
    if (cameraId) {
        videoConstraints = Object.assign(Object.assign({}, videoConstraints), { deviceId: cameraId });
    }
    else {
        videoConstraints = Object.assign(Object.assign({}, videoConstraints), { deviceId: "default" });
    }
    return videoConstraints;
};
exports.getVideoConstraints = getVideoConstraints;
const getAudioConstraints = (micId) => {
    let audioConstraints = {};
    if (micId) {
        audioConstraints = {
            deviceId: micId,
        };
    }
    else {
        audioConstraints = {
            deviceId: "default",
        };
    }
    return audioConstraints;
};
exports.getAudioConstraints = getAudioConstraints;
